import React, {useEffect, useState, useContext} from "react";
import clsx from "clsx";
import { Icon } from "../elements/Icon";
import {LineContext} from "../context/LineContext";

export const StudyProgram = ({
                               keyId,
                               label,
                               type,
                               areaCode,
                               ...props
                             }) => {
  let typeAcronym = type === "Yrkesfaglig utdanningsprogram" ? "Yrkeskompetanse" : "Studiekompetanse";
  const classAttr = clsx("program-area", `button--${typeAcronym}`);
  const theLine = useContext(LineContext);
  const [theLabel, setTheLabel] = useState(label);

  useEffect(() => {
    setTheLabel(label.replace(/^vg*\d*\s*/ig, ''))
  }, [label]);

  const handleClick = () => {
    let found = []
    if (theLine.vgStage === 1) {
      found = theLine.items.find((element) => element.programomradekode6 === keyId);
    } else {
      if (theLine.velgProgress && theLine.vgStage) {
        found = theLine.velgProgress[theLine.vgStage-1].line.children.find((element) => element.programomradekode6 === keyId);
      }
    }
    let progress = theLine.velgProgress

    let period = found.varighet
    period = period ? parseInt(period) : 1
    if (period === 1) {
      progress[theLine.vgStage] = {
        line: found
      }
      if (found && (found.opplaeringssted.toLowerCase() !== 'skole')) {
        progress[theLine.vgStage].school = {name: 'Arbeide i bedrift', id: 'Lærling'};
      }
    } else {
      for (let i=0; i < period; i++) {
        progress[theLine.vgStage + i] = {
          line: found
        }
        if (found && found.opplaeringssted.toLowerCase() !== 'skole') {
          progress[theLine.vgStage + i].school = {name: 'Arbeide i bedrift', id: 'Lærling'};
        }
      }
    }

    theLine.setVelgStep(theLine.velgStep+1);
    theLine.setVelgProgress(progress);

  };

  return (
    <button
      className={classAttr}
      type="button"
      {...props}
      onClick={() => handleClick()}
    >
      <div className="program-area--labels">
        <h2 className="program-name">{theLabel}</h2>
        <div className="program-section">{typeAcronym}</div>
      </div>
      <div className="program-area--icon-after">
        <Icon className="icon--after" size="lg" name="chevron-right" />
      </div>
    </button>
  );
};
