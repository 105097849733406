import {ReactComponent as HomeIcon} from "../../asset/home.svg";
import {ReactComponent as Expand} from "../../asset/expand-more.svg";
import ArticleTeaser from "../component/ArticleTeaser";
import Profile from "../component/Profile";
import React, {lazy, Suspense, useContext, useEffect, useState} from "react";
import clsx from "clsx";
import {UserContext} from "../context/UserContext";
import {useLocation} from "react-router-dom";

const SearchForm = lazy(() => import('../component/SearchForm'))
const renderLoader = () => <p>Loading</p>;

const Home = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [articleFilter, setArticleFilter] = useState('Alle');
  const [searchParam, setSearchParam] = useState(null);
  /**
   * articles storing the API result, while searchResults is used for UI rendering.
   * This is especially useful because we keep the original list, after searching or filtering, we can refer to.
   */
  const [articles, setArticles] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [error, setError] = useState(null);
  const [user] = useContext(UserContext);
  let location = useLocation();


  const fetchArticles = () => {
    let nowTime = new Date().getTime()
    let fetchTime = window.localStorage.getItem('artikkel_fetch_time')
    let cachedData = window.localStorage.getItem('artikkel_api_result')
    if (fetchTime && cachedData && parseInt(fetchTime) > nowTime - 60 * 60 * 1000) {
      setIsLoaded(true);
      setArticles(JSON.parse(cachedData));
      setSearchResults(JSON.parse(cachedData));
    } else {
      let url = new URL("https://api.utdanning.no/linje/artikkel");
      url.searchParams.append('fields', 'nid,tittel,ingress,kategori')
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            setArticles(result);
            setSearchResults(result);
            setIsLoaded(true);
            window.localStorage.setItem('artikkel_fetch_time', JSON.stringify(new Date().getTime()))
            window.localStorage.setItem('artikkel_api_result', JSON.stringify(result))
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }
  }

  useEffect( () => {
    fetchArticles();
    window.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        setFilterOpen(false);
      }
    })
  }, []);

  /**
   * On page load or URL query params change, we check if there is a parameter called sok (søk),
   * if so, we run the search.
   * This is a bit more flexible than running search by passing the event via props.
   * Also search will work after page refresh.
   */
  useEffect( () => {
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.sok && params.sok !== searchParam) {
      setSearchParam(params.sok)
    } else if (searchParam && params && !params.sok) {
      setSearchResults(articles)
    }
  }, [location, searchParam, articles]);
  useEffect( () => {
    if (searchParam) {
      let url = new URL("https://api.utdanning.no/linje/sok");
      url.searchParams.append('Query', searchParam)
      url.searchParams.append('Type', 'linje_artikkel')
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            const resultNids = []
            if (result.count) {
              result.results.map(r => resultNids.push(r.nid))
            }
            let searchResults = articles.filter(article => {
              return resultNids.includes(article.nid)
            })
            setSearchResults(searchResults)
          },
          (error) => {
            setError(error);
          }
        )
    }
  }, [searchParam, articles]);

  // Dropdown filter.
  const setFilter = filter => {
    if (filter !== 'Alle') {
      setSearchResults(articles.filter(article => article.kategori.toLowerCase() === filter.toLowerCase()));
    } else {
      setSearchResults(articles);
    }
    setArticleFilter(filter);
    setFilterOpen(false);
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <div className="articles-list">
          <Profile />
          <div className="profile">
            <div className="page-title">
              <HomeIcon/>
              <h1>Hei, {user.fornavn}</h1>
            </div>
            <h3>Hva vil du lese om?</h3>
          </div>
          <Suspense fallback={renderLoader()}>
            <SearchForm formId="articlesSearch"/>
          </Suspense>
          <hr className="top-hr"/>
          <div className={clsx({'open': filterOpen}, "articles-filter")}>
            <div className="selected-filter" onClick={() => {
              setFilterOpen(!filterOpen)
            }}>
              <span>{articleFilter}</span>
              <Expand className="article-filter-chevron"/>
            </div>
            <ul>
              <li onClick={() => {
                setFilter('Alle')
              }}>Alle
              </li>
              <li onClick={() => {
                setFilter('Kompetanser')
              }}>Kompetanser
              </li>
              <li onClick={() => {
                setFilter('Om VGS')
              }}>Om VGS
              </li>
              <li onClick={() => {
                setFilter('Søking')
              }}>Søking
              </li>
              <li onClick={() => {
                setFilter('Valg')
              }}>Valg
              </li>
            </ul>
          </div>
          <div className="articles">
            {searchResults.length ?
              searchResults.map((article) => {
                return <ArticleTeaser articleContent={article} key={article.nid}/>
              }) :
              <h2>Ingen resultater funnet!</h2>
            }
          </div>
        </div>
      </>
    );
  }
};

export default Home;
