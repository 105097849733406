import React, { useState, useEffect } from "react";
import Linje from "./Linje";
import {Icon} from "../../elements/Icon";
import {Button} from "../../elements/Button";
import {useNavigate} from "react-router-dom";

const MineLinjer = () => {
  const [lines, setLines] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let mineLinjer = localStorage.getItem('savedLines')
    mineLinjer = mineLinjer ? JSON.parse(mineLinjer) : []
    setLines(mineLinjer)
  }, []);

  const newLine = () => {
    navigate('/velg')
  }

  const removeLine = (index) => {
    let newLines = [...lines];
    newLines.splice(index, 1);
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Er du sikkert på at du vil slette dette linjen?')){
      saveTheLines(newLines)
    }
  }

  const arrangeLines = (line, index, dir) => {
    let newLines = [...lines];
    newLines.splice(index, 1);
    let newLocation = dir === 'up' ? index - 1 : index + 1;
    newLines.splice(newLocation, 0, line);
    saveTheLines(newLines);
    document.getElementById(`line${newLocation}`).scrollIntoView()
  }

  const saveTheLines = lines => {
    setLines(lines);
    localStorage.setItem('savedLines', JSON.stringify(lines));
  }

  return (
    <>
      <div className="sort-lines">
        <Icon name="sort" /><p>Klikk på pilene for å endre rekkefølge</p>
      </div>
      {lines && lines.length ?
        lines.map((line, index) => {
          return (
            <div className="sort-lines--line" key={index} id={`line${index}`}>
              <div className="arrange">
                {index > 0 &&
                  <div className="up" onClick={() => arrangeLines(line, index, 'up')}>↑</div>
                }
                {index < lines.length-1 &&
                  <div className="down" onClick={() => arrangeLines(line, index, 'down')}>↓</div>
                }
              </div>
              <Linje onRemove={() => {removeLine(index)}} line={line} index={index} />
            </div>
          );
        })
        : <p>Ingen linje ennå!</p>
      }
      <Button onClick={newLine} className="create-new-line" variant="dark" size="fw" label="Bygg en ny linje" />
    </>
  );
};

export default MineLinjer;
