const CalendarEvent = ({event}) => {
  const theDate = () => {
    let date = event.dato
    return new Date(date)
  }
  const convertDate = () => {
    let monthes = ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'];
    const date = theDate()
    return date.getDate() + ' . ' + monthes[date.getMonth()];
  }

  const daysTo = () => {
    return parseInt((theDate().getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24);
  }
  return (
    <>
      <div className="event">
        <div className="event-date">
          <div className="deadline">
            <span>Frist: </span>{convertDate()}
          </div>
          <div className="daysto">
            {daysTo()} <span>dager igjen</span>
          </div>
        </div>
        <h3 className="title">{event.tittel}</h3>
        <p className="event-details" dangerouslySetInnerHTML={{ __html: event.tekst}} />
      </div>
    </>
  );
}
export default CalendarEvent;