import React, {useContext} from "react";
import {Fylke} from "./Fylke";
import {LineContext} from "../../context/LineContext";

const VelgFylke = () => {
  const theLine = useContext(LineContext);

  return (
    <div className="select-fylke">
      <div className="select-fylke--header">
        <p>Helsearbeiderfag tilbys på flere skoler.<br />
          Hvilken skole vil du gå på?</p>
        <h2>Velg først fylket ditt!</h2>
      </div>
      <div className="select-fylke--fylker">
        {Object.keys(theLine.skoleListe).map(fylke => {
          return <Fylke key={fylke} fylke={theLine.skoleListe[fylke]} />
        })}
      </div>
    </div>
  );
}

export default VelgFylke;