import React, {useState, useEffect} from "react";
import {Button} from "../../elements/Button";

const Linje = ({line, index, onRemove}) => {
  const [title, setTitle] = useState('');
  const [certification, setCertification] = useState('');

  useEffect(() => {
    if (line && line[1].line.utdanningsprogram_tittel) {
      setTitle(line[1].line.utdanningsprogram_tittel)
    }
    let certificates = [];
    Object.keys(line).forEach(p => {
      if (line[p].line.sluttkompetanse) {
        certificates.push(line[p].line.sluttkompetanse)
      }
    })
    certificates = certificates.filter((val, i, self) => self.indexOf(val) === i);
    setCertification(certificates.join(' og '))
  }, [line])

  const SetProgramTitle = ({title}) => {
    let isPaabygg = title.toLowerCase().indexOf('vg4 påbygg') > -1
    return isPaabygg ? 'Påbygg etter fagbrev' : `${title.replace(/^vg*\d*\s*/ig, '')}`
  }

  return (
    <div className="saved-lines">
      <div className="header">
        <div className="number">{index + 1}</div>
        <div className="line-title">{title}</div>
        <Button onClick={onRemove} variant="text" className="close-selection-btn" iconBefore="close" label="" />
      </div>
      <div className="body">
        <div className="line-steps">
          {Object.keys(line).map(l => {
            return (
              <div className="line-step" key={l}>
                <div className="vg-stage">
                  {(Object.keys(line).length !== parseInt(l) && line[l].line.programomrade_tittel.toLowerCase().indexOf('vg4 påbygg') < 0) &&
                    `Vg${l}`
                  }
                </div>
                <div className="vg-details">
                  <div className="vg-title"><SetProgramTitle title={line[l].line.programomrade_tittel} /></div>
                  <div className="vg-school">{line[l].school.name}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="certification-type">Du får {certification}</div>
      </div>
    </div>
  );
}

export default Linje;