import {Button} from "./Button";

export const ToTheTop = () => {
  return (
    <>
      <div className="to-the-top">
        <Button onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'})}} variant="dark" size="fw" label="Til toppen"/>
      </div>
    </>
  );
}
