import React, {useContext, useEffect, useState} from 'react'
import { StudyProgram } from "../../component/StudyProgram";
import {LineContext} from "../../context/LineContext";

const VgOthers = () => {
  const theLine = useContext(LineContext)
  const [vgItems, setVgItems] = useState([])

  useEffect(() => {
    let items = theLine.velgProgress[theLine.vgStage - 1].line.children
    const isLaerling = theLine.nthDigit(theLine.velgStep, 3)
    if (isLaerling) {
      setVgItems(items.filter(item => item.opplaeringssted.toLowerCase() !== 'skole'))
    } else {
      setVgItems(items.filter(item => item.opplaeringssted.toLowerCase() === 'skole'))
    }
  }, [theLine]);

  return (
    <>
      <div className="page-header">
        <h1 className="class-stage">Vg{theLine.vgStage}:</h1>
        <div className="stage-desc">Hvilken retning vil du prøve?</div>
      </div>

      <div className="program-areas">

        {vgItems.map((item) => (
          <StudyProgram
            key={item.programomradekode6}
            keyId={item.programomradekode6}
            type={item.utdanningsprogram_type || ''}
            label={item.programomrade_tittel}
            areaCode={item.programomradekode6}
          />
        ))}
      </div>
    </>
  )
}

export default VgOthers
