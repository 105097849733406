import { BrowserRouter as Router } from "react-router-dom";
import * as Layout from "./elements/layout";
import {Routing} from "./component/Routing";
import { Header } from "./component/Header";
import {UserProvider} from "./context/UserContext";
import {ModalProvider} from "./context/ModalContext";
import {LineProvider} from "./context/LineContext";

function App() {
  return (
    <UserProvider>
      <Router>
          <Layout.Container>
            <LineProvider>
              <ModalProvider>
                <Layout.Aside>
                  <Header />
                </Layout.Aside>
                <Layout.Main>
                  <Routing />
                </Layout.Main>
              </ModalProvider>
            </LineProvider>
          </Layout.Container>
      </Router>
    </UserProvider>
  );
}

export default App;
