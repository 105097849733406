import React, {useEffect, useContext, useState} from "react";
import {LineContext} from "../../context/LineContext";
import {Icon} from "../../elements/Icon";

const VelgDirection = () => {
  const theLine = useContext(LineContext);
  const [certificate, setCertificate] = useState('');
  const [etter, setEtter] = useState('');

  useEffect(() => {
    let cert = theLine.velgProgress[1].line.utdanningsprogram_tittel
    setCertificate(cert)
    if (theLine.nthDigit(theLine.velgStep, 3)) {
      setEtter('lærlingtiden')
    } else {
      setEtter('utdanningen')
    }
  }, [theLine]);

  const handleClick = (direction) => {
    if (direction === 'toWork') {
      theLine.setVelgStep(theLine.velgStep + 1)
    } else {
      // If påbygg selected, just store it and goto line summary since there is always only one påbygg available
      let paabygg = Object.keys(theLine.velgProgress)
      if(Array.isArray(paabygg)) {
        let lastYear = paabygg.slice(-1)
        paabygg = theLine.velgProgress[lastYear];
        paabygg = paabygg.line.children[0];
        let progress = theLine.velgProgress
        progress[parseInt(lastYear)+1] = {
          line: paabygg
        }
        theLine.setVelgProgress(progress);
        theLine.setVelgStep(4002)
      }
    }
  }

  return (
    <div className="select-direction after-laerling">
      <div className="select-direction--header">
        <p>Etter {etter}:</p>
        <h2>Hva vil du gjøre nå?</h2>
      </div>
      <div className="select-direction--choices">
        <button
          className="program-area button--Yrkeskompetanse"
          type="button"
          onClick={() => handleClick('toWork')}
        >
          <div className="program-area--labels">
            <h2 className="program-name">Ut i arbeid</h2>
            <div className="program-section">Du er nå utdannet {certificate}</div>
          </div>
          <div className="program-area--icon-after">
            <Icon className="icon--after" size="lg" name="chevron-right" />
          </div>
        </button>
        <button
          className="program-area button--Studiekompetanse"
          type="button"
          onClick={() => handleClick('paabygg')}
        >
          <div className="program-area--labels">
            <h2 className="program-name">Påbygg</h2>
            <div className="program-section">Få generell studiekompetanse</div>
          </div>
          <div className="program-area--icon-after">
            <Icon className="icon--after" size="lg" name="chevron-right" />
          </div>
        </button>

      </div>
    </div>
  );
}

export default VelgDirection;