import {Link} from "react-router-dom";
import React  from "react";
import { ReactComponent as Heart } from "../../../asset/heart.svg";
import {Button} from "../../elements/Button";

const MinLinje = () => {
  return (
    <>
      <div className="min-linje">
        <div className="page-title">
          <Heart />
          <h1>Min linje</h1>
        </div>
        <p>Prøv å bygge din egen skolehverdag:</p>
        <ol>
          <li>Sjekk ut et utdanningsprogram</li>
          <li>Les om fagene</li>
          <li>Velg skole og se fjorårets snitt</li>
          <li>Se hvilken kompetanse du får</li>
          <li>Lagre favoritter til profilen din</li>
        </ol>
        <Link to="/velg">
          <Button
            variant="secondary"
            size="fw"
            className="mn-linje-button"
            iconAfter="chevron-right"
            label="Sett i gang" />
        </Link>
      </div>
    </>
  );
};

export default MinLinje;
