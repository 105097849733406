import React, {useContext} from "react";
import { Icon } from "../../elements/Icon";
import {UserContext} from "../../context/UserContext";

export const Fylke = ({fylke}) => {
  const [user, setUser] = useContext(UserContext);
  const handleClick = () => {
    setUser({
      fornavn: user.fornavn,
      etternavn: user.etternavn,
      avatar: user.avatar,
      fylke: {
        id: fylke.fylkenr,
        name: fylke.fylkenavn
      }
    })
  };

  return (
    <button type="button" onClick={() => handleClick()}>
      <div className="school-labels">
        <h3 className="school-name">{fylke.fylkenavn}</h3>
      </div>
      <div className="school-icon-after">
        <Icon size="lg" name="chevron-right" />
      </div>
    </button>
  );
};
