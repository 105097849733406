import UserAvatar from "../pages/Profile/UserAvatar";
import {NavLink} from "react-router-dom";
import {Button} from "../elements/Button";
import React, {useContext} from "react";
import {UserContext} from "../context/UserContext";

const Profile = () => {
  const [user] = useContext(UserContext);

  return (
    <div className="profile-component">
      <div className="avatar">
        <UserAvatar avatarId={user.avatar} />
      </div>
      <div className="detail">
        <h1>{user.fornavn} {user.etternavn}</h1>
        <NavLink to="/profil">
          <Button variant="text" label="Se profilen min" />
        </NavLink>
      </div>
    </div>
  );
}
export default Profile;