import {useState} from "react";
import {Link} from "react-router-dom";
import BookmarkIcon from "../elements/BookmarkIcon";

const ArticleTeaser = ({articleContent}) => {
  // Import images dynamically based on article category fetched from API
  const [image, setImage] = useState('');
  (imageName => {
    let category = articleContent.kategori.toLowerCase();
    category = category.replace(/\s/g, '-', category)
    import(`../../asset/images/${category}.svg`).then(image => {
      setImage(image.default)
    })
  })(articleContent.image)

  return (
    <>
      <div className="article">
        <img src={image} alt={articleContent.tittel}/>
        <BookmarkIcon articleId={articleContent.nid}/>
        <div className="article--details">
          <h3 className="category">{articleContent.kategori}</h3>
          <Link to={"/artikkel/" + articleContent.nid}>
            <h2>{articleContent.tittel}</h2>
            <p>{articleContent.ingress}</p>
          </Link>
        </div>
      </div>
    </>
  );
}

export default ArticleTeaser;