import {Button} from "../../elements/Button";
import React, {useContext, useEffect, useState} from "react";
import {LineContext} from "../../context/LineContext";
import clsx from "clsx";
import {Icon} from "../../elements/Icon";
import {saveTheLine} from "./Functions";
import {ModalContext} from "../../context/ModalContext";

const VelgHeader = () => {
  const theLine = useContext(LineContext);
  const [lines, setLines] = useState([]);
  const [isWavy, setIsWavy] = useState(false);
  const [isSummary, setIsSummary] = useState(false);
  const [certification, setCertification] = useState('');
  const theModal = useContext(ModalContext)

  useEffect(() => {
    let l = [];
    let certificates = []
    if (theLine.velgProgress &&
        Object.keys(theLine.velgProgress).length
    ){
      Object.keys(theLine.velgProgress).forEach(p => {
        let programTitle = p === '1001' ? theLine.velgProgress[p].line.utdanningsprogram_tittel : theLine.velgProgress[p].line.programomrade_tittel
        let isPaabygg = programTitle.toLowerCase().indexOf('vg4 påbygg') > -1
        programTitle =  isPaabygg ?
                        'Påbygg etter fagbrev' :
                        `Vg${p}: ${programTitle.replace(/^vg*\d*\s*/ig, '')}`
        let lastItem = Object.keys(theLine.velgProgress).length === parseInt(p);
        let sluttKompetanse = '';
        if (theLine.velgProgress[p].line.sluttkompetanse) {
          sluttKompetanse = lastItem && !isPaabygg ? theLine.velgProgress[p].line.sluttkompetanse : null
          if (!certificates.includes(theLine.velgProgress[p].line.sluttkompetanse)) {
            certificates.push(theLine.velgProgress[p].line.sluttkompetanse)
          }
        }
        setCertification(certificates.join(' og '))
        l.push({
          vgStage: p,
          title: programTitle,
          type: theLine.velgProgress[p].line.utdanningsprogram_type,
          school: theLine.velgProgress[p].school ? theLine.velgProgress[p].school.name : '',
          schoolId: theLine.velgProgress[p].school ? theLine.velgProgress[p].school.id : null,
          sluttKompetanse,
          isPaabygg
        })
      })

    }
    setLines(l)
    if (theLine.steps.summary.includes(theLine.velgStep)) {
      setIsSummary(true);
    } else {
      setIsSummary(false);
    }
    // To show or not the top section in white background
    if (!theLine.steps.vg1.includes(theLine.velgStep) && !theLine.steps.lineSummary.includes(theLine.velgStep)) {
      setIsWavy(true)
    } else {
      setIsWavy(false)
    }
    setTimeout(() => {
      if (document && document.querySelector('.dashed-before-list')) {
        let theList = document.querySelector('.dashed-before-list');
        let lastListItem = theList.querySelector('li:last-child');
        let lastChildHeight = lastListItem.getBoundingClientRect()
        let root = document.querySelector(':root');
        root.style.setProperty('--border-bottom', lastChildHeight.height + 'px - 19px')
      }
    }, 300)
  }, [theLine.velgProgress, theLine.velgStep, theLine.steps])

  const goBack = () => {
    let flow = theLine.velgFlow
    if (flow.length > 1) {
      flow.pop()
      theLine.setVelgFlow(flow)
      let newStep = flow[flow.length - 1]
      theLine.setVelgStep(newStep);
      if (theLine.velgProgress[theLine.vgStage + 1]) {
        let progress = theLine.velgProgress
        let progressLength = Object.keys(progress).length
        for (let i = theLine.vgStage+1; i<=progressLength; i++) {
          delete progress[i]
        }
        theLine.setVelgProgress(progress)
      }
    }
  }

  const saveLine = () => {
    if (saveTheLine(theLine, true)) {
      theModal.setClassName('full-screen-modal')
      theModal.setModal('pages/MinLinje/LineSaved')
    }
  }

  const closeLine = () => {
    theModal.setClassName('full-screen-modal')
    theModal.setModal('pages/MinLinje/LeavingLine')
  }

  return (
    <div className={clsx({"is-wavy": isWavy}, {"is-summary": isSummary}, "velg-header")}>
      <div className={clsx({"flex-end": theLine.velgStep === 1001}, "velg-header--actions")}>
        { theLine.velgStep !== 1001 &&
          <Button variant="text" iconBefore="chevron-right" label="Gå tilbake" onClick={goBack}/>
        }
        <Button onClick={closeLine} variant="text" className="close-selection-btn" iconBefore="close" label="" />
      </div>
      {isSummary &&
        <div className="summary-header">
          <h1>Oppsummering</h1>
          <div>Her er utdanningsløpet du har laget!</div>
        </div>
      }
      <svg id="wavyBg" viewBox="0 0 500 450" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M500 0H0V321V421C75 471 162.5 446 250 421C337.5 396 425 371 500 421V321V0Z" fill="white"/>
      </svg>

      {isWavy &&
        <div className="velg-summary">
          <ul className={clsx({"dashed-before-list": lines.length > 1}, "orange-bullets")}>
            {lines.map(line => {
              return (
                <li className={clsx({'is-paabygg': line.isPaabygg})} key={line.vgStage}>
                  <h3>{line.title}</h3>
                  <p>{line.type}</p>
                  {line.school &&
                    <p className="school-name">
                      {line.schoolId !== 'Lærling' &&
                        <Icon size="md" name="study-hat" />
                      }
                      {line.school}</p>
                  }
                  {line.sluttKompetanse &&
                    <p className="line-sluttkompetanse">Du har nå fått: {line.sluttKompetanse}</p>
                  }
                </li>
              )
            })}
          </ul>
          {isSummary &&
            <>
              <p className="certification-type">Du har nå fått: <strong>{certification}</strong></p>
              <Button iconBefore="heart" size="lg"
                      variant="secondary" className="save-my-line-btn"
                      onClick={saveLine} label="Lagre løpet mitt"/>
            </>
          }
        </div>
      }

    </div>
  );
};

export default VelgHeader;