import React, {useState, createContext, useEffect, useRef} from 'react';

export const LineContext = createContext(null);

export const LineProvider = props => {
  const [velgStep, setVelgStep] = useState(1001);
  const [velgFlow, setVelgFlow] = useState([]);
  const [vgStage, setVgStage] = useState(1);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [velgProgress, setVelgProgress] = useState({});
  const [skoleListe, setSkoleliste] = useState({});
  const [redirectAfter, setRedirectAfter] = useState('/');

  // A function to get nth digit from a bigger number. Ex: third digit in 2103
  const nthDigit = (number, n) => {
    return Math.floor((number / Math.pow(10, n - 1)) % 10);
  }

  /**
   * Since each different choice has its consequences, steps are not linear increments.
   * Below object is a representation of which components must be rendered based on the selection step.
   * The chart and spreadsheet are inside doc directory.
   * @type {{velgDirection: number[], summary: number[], vg1: number[], etterLaerling: number[], vgOthers: number[], lineSummary: number[], velgSkole: number[]}}
   */
  const steps = {
    vg1: [1001],
    vgOthers: [2001, 2101, 2201, 3001, 3101, 3201, 4001],
    lineSummary: [1002, 2002, 2202, 3002, 3202, 4002],
    velgSkole: [1003, 2003, 2203, 3003, 3203, 4003],
    velgDirection: [1004, 2004],
    etterLaerling: [2102, 2204, 3102, 3204],
    summary: [2103, 2205, 3004, 3103, 3205, 4004]
  };

  const theLine = {
    steps,
    velgStep,
    setVelgStep,
    error,
    isLoaded,
    setIsLoaded,
    items,
    vgStage,
    setVgStage,
    setItems,
    velgProgress,
    setVelgProgress,
    skoleListe,
    setSkoleliste,
    redirectAfter,
    setRedirectAfter,
    velgFlow,
    setVelgFlow,
    nthDigit
  }

  useEffect(() => {
    // Load vgStage and VelgStep from LocalStorage if they are set
    let vStep = localStorage.getItem('lineVelgStep');
    let vProgress = localStorage.getItem('velgProgress')
    let fSteps = localStorage.getItem('lineVelgFlow')
    if (vStep && vProgress) {
      setVelgStep(JSON.parse(vStep));
      setVelgProgress(JSON.parse(vProgress));
      theLine.setVelgFlow(JSON.parse(fSteps));
    }
    if (!vProgress || (vProgress && !Object.keys(JSON.parse(vProgress)).length)) {
      theLine.setVelgFlow([]);
    }
    /**
     * Check if programs are already stored in localStorage and not older than 6 hours
     * If so, load them from localStorage, otherwise, fetch from API
     *
     */
    let nowTime = new Date().getTime();
    let fetchTime = window.localStorage.getItem("programomrade_fetch_time");
    let cachedData = window.localStorage.getItem("programomrade_api_result");
    if (
      fetchTime &&
      cachedData &&
      parseInt(fetchTime) > nowTime - 60 * 60 * 6 * 1000
    ) {
      setIsLoaded(true);
      setItems(JSON.parse(cachedData));
    } else {
      fetch("https://api.utdanning.no/linje/programomrade")
        .then((res) => res.json())
        .then(
          (result) => {
            let sortedRes = result.sort((a,b) => (a.utdanningsprogram_type < b.utdanningsprogram_type) ? 1 : ((b.utdanningsprogram_type < a.utdanningsprogram_type) ? -1 : 0))
            setIsLoaded(true);
            setItems(sortedRes);
            window.localStorage.setItem(
              "programomrade_fetch_time",
              JSON.stringify(new Date().getTime())
            );
            window.localStorage.setItem(
              "programomrade_api_result",
              JSON.stringify(result)
            );
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    /**
     * Get skoleListe from localStorage if already fetched and set and not older than six hours.
     * If not set yet:
     * 1. Fetch from API
     * 2. Sort the list by FylkeNavn
     * 3. Cleanup response and remove stuff we don't want to keep memory consumption low
     * 4. Then save it to localStorage
     *
     */
    let cachedSkoleliste = window.localStorage.getItem('skoleListe');
    if (
      fetchTime &&
      cachedSkoleliste &&
      parseInt(fetchTime) > nowTime - 60 * 60 * 6 * 1000
    ) {
      setSkoleliste(JSON.parse(cachedSkoleliste));
    } else {
      fetch("https://api.utdanning.no/linje/skoleliste")
        .then((res) => res.json())
        .then(
          (result) => {
            if (result) {
              let sortedRes = result.sort((a,b) => (a.fylkenavn > b.fylkenavn) ? 1 : ((b.fylkenavn > a.fylkenavn) ? -1 : 0))
              let skoler = []
              let index = 0
              sortedRes.map(fylke => {
                if (fylke.skoler) {
                  skoler[index] = {
                    fylkenr: fylke.fylkenr,
                    fylkenavn: fylke.fylkenavn,
                    skoler: {}
                  }
                  fylke.skoler.map(skole => {
                    skoler[index].skoler[skole.vilbli_org_id] = {
                      skolenavn: skole.skolenavn,
                      skoleregionnr: skole.skoleregionnr,
                      vilbli_org_id: skole.vilbli_org_id
                    }
                    return null;
                  })
                  index++;
                }
                return null;
              })
              setSkoleliste(skoler);
              window.localStorage.setItem("skoleListe", JSON.stringify(skoler));
            }
          },
          (error) => {
            setError(error);
          }
        );
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Everytime something changes, store it in the localStorage for persistance purposes
    localStorage.setItem('lineVelgStep', JSON.stringify(velgStep))
    localStorage.setItem('velgProgress', JSON.stringify(velgProgress))
  }, [velgStep, velgProgress])

  const firstUpdate = useRef(true)
  useEffect(() => {
    // Store the flow of steps selected by thr user. This is useful while trying to go back to previews step.
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    let theFlow = velgFlow
    if (!theFlow.includes(velgStep)) {
      theFlow.push(velgStep)
    }
    setVelgFlow(theFlow)
    localStorage.setItem('lineVelgFlow', JSON.stringify(velgFlow))
  }, [velgStep, velgFlow])

  useEffect(() => {
    // This is to determine which VG step the user is in to show appropriate VG{x}
    setVgStage(nthDigit(velgStep, 4))
  }, [velgStep])

  return (
    <LineContext.Provider value={theLine}>
      {props.children}
    </LineContext.Provider>
  );
}