import { Route, Routes } from 'react-router-dom';
import Faq from '../pages/Faq';
import About from '../pages/About';
import Home from '../pages/Home';
import Article from '../pages/Article';
import Kalender from '../pages/Kalender';
import Velg from '../pages/MinLinje/Velg';
import MinLinje from '../pages/MinLinje/MinLinje';
import Profile from "../pages/Profile/Profile";

export const Routing = () => {
  return (
    <Routes>
        <Route path="/faq" element={<Faq/>} />

        <Route path="/om-oss">Om Oss</Route>

        <Route path="/about" element={<About />} />

        <Route path="/velg" element={<Velg />} />

        <Route path="/profil" element={<Profile />} />

        <Route path="/min-linje" element={<MinLinje />} />

        <Route path="/" element={<Home />} />
        <Route path="/artikkel/:nid" element={<Article/>} />
        <Route path="/kalender" element={<Kalender/>} />

    </Routes>
  );
};
