import React, {useContext} from "react";
import {LineContext} from "../../context/LineContext";
import clsx from "clsx";
import {Button} from "../../elements/Button";
import {ModalContext} from "../../context/ModalContext";

const Summary = () => {
  const theLine = useContext(LineContext)
  const theModal = useContext(ModalContext)
  const classAttr = clsx("velg-total-summary");

  const navigateTo = (to) => {
    theLine.setRedirectAfter(to)
    if (Object.keys(theLine.velgProgress).length) {
      theModal.setClassName('full-screen-modal')
      theModal.setModal('pages/MinLinje/LeavingLine')
    }
  }

  return (
    <>
      <div className={classAttr}>
        <Button onClick={() => {navigateTo('/')}} variant="secondary" size="fw" label="Gå til hovedsiden" />
        <Button onClick={() => {navigateTo('/velg')}} variant="secondary" size="fw" label="Sjekk ut en nytt løp" />
      </div>
    </>
  )
}

export default Summary;
