import React, {useContext} from "react";
import {LineContext} from "../../context/LineContext";
import clsx from "clsx";
import {Button} from "../../elements/Button";

const LineSummary = () => {
  const theLine = useContext(LineContext);
  let item = theLine.velgProgress[theLine.vgStage].line
  const typeAcronym = item.utdanningsprogram_type === "Yrkesfaglig utdanningsprogram" ? "yrkeskompetanse" : "studiekompetanse";
  const classAttr = clsx("line-summary", `line--${typeAcronym}`);
  let subjectHours = item.timefordeling
  let interesser = [];
  if (item.interesser && item.interesser.length) {
    interesser = item.interesser;
  }

  let hours = []
  let totalHours = 0
  subjectHours.map(subject => {
    let subjects = []
      subject.timefordeling.map(s => {
        subjects.push(s.fag_tittel)
        return null;
    })
    totalHours += parseInt(subject.timetall)
    let fag = {
      title: subject.fag_tittel,
      time: subject.timetall,
      percent: 0,
      subjects: subjects,
      farge: subject.farge
    }
    hours.push(fag)
    return null;
  });

  hours = hours.map(h => {
    h.percent = parseInt((h.time / totalHours) * 100)
    return h
  })
  hours.sort((a, b) => b.time - a.time)


  const handleSubmit = () => {
    theLine.setVelgStep(theLine.velgStep+1);
  };


  return (
    <div className={classAttr}>
      <div className="section-color" />
      <div className="line-summary--content">
        <div className="program-section">
          {theLine.vgStage === 4 ?
            'studiekompetanse' :
            item.utdanningsprogram_type
          }
        </div>
        <div className="section-desc">
          <h2>VG{theLine.vgStage}: {
            theLine.vgStage === 4 ?
              'Påbygg' :
              item.programomrade_tittel.replace(/^vg*\d*\s*/ig, '')
            }
          </h2>
          {theLine.vgStage === 4 &&
            <>
              <h3>Passer for deg om du vil:</h3>
              <ul className="orange-bullets">
                <li>Studere på universitet eller høgskole</li>
              </ul>
            </>
          }
          {theLine.vgStage !== 4 && interesser &&
            <>
              <h3>Har du interesserer for:</h3>
              <ul className="orange-bullets">
                {interesser.map(interesse => {
                  return <li key={interesse}>{interesse}</li>
                })}
              </ul>
            </>
          }
          <h2>Fag</h2>
          <div className="line-subject-percentages">
            <div className="percentage-presentration">
              {hours.map(h => {
                return <div className="subject" style={{flexBasis: h.percent + "%", backgroundColor: h.farge}} key={h.time}>{h.percent}%</div>
              })}
            </div>
          </div>
          <div className="subject-details">
            {hours.map(h => {
              return <div className="detail" key={h.time}>
                  <p className="subject-title" style={{backgroundColor: h.farge}}>{h.title}</p>
                  <ul className="orange-bullets">
                    {h.subjects.map(s => {
                      return <li key={s}>{s}</li>
                    })}
                  </ul>
              </div>
            })}
          </div>
        </div>
        <Button onClick={handleSubmit} variant="dark" size="fw" label="Velg linjen" />
      </div>
    </div>
  )
}

export default LineSummary;
