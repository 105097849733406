import React, {useEffect, useState, useContext} from "react";
import {LineContext} from "../../context/LineContext";
import {UserContext} from "../../context/UserContext";
import {School} from "./School";
import {Button} from "../../elements/Button";

const VelgSkole = () => {
  const theLine = useContext(LineContext);
  const [user] = useContext(UserContext);
  const [skoler, setSkoler] = useState([]);
  const [andreFylkerMedSkole, setAndreFylkerMedSkole] = useState([]);
  const [skoleHarPaabygg, setSkoleHarPaabygg] = useState(false);
  const [isLastYear, setIsLastYear] = useState(false);
  const [skolerMedLopet, setSkolerMedLopet] = useState([]);
  const [skolerUtenLopet, setSkolerUtenLopet] = useState([]);
  const [aarSomLaerling, setAarSomLaerling] = useState('');
  const [currentLine] = useState(Object.keys(theLine.velgProgress).length);


  useEffect(() => {
    let matchedSchools = []
    if (theLine.velgProgress &&
        theLine.velgProgress[currentLine].line &&
        theLine.velgProgress[currentLine].line.skoler
    ) {
      const fylkeListe = theLine.skoleListe.find(skoler => skoler.fylkenr === user.fylke.id)
      theLine.velgProgress[currentLine].line.skoler.forEach(skole => {
        if (fylkeListe.skoler[skole]) {
          let theSchool = fylkeListe.skoler[skole];
          theSchool.link = 'https://utdanning.no/org/' + skole
          matchedSchools.push(theSchool)
        }
      })

      /**
       * Here we check if the same school has been selected for previous stages.
       * If so, then we check if the same school offers Påbygg.
       * But first, we need to know if this is the last year
       *
       */
      if(!theLine.velgProgress[currentLine].line.children) {
        setIsLastYear(true);
        let sameSchoolForWholeLine = '';
        for (let i=1; i<=currentLine; i++) {
          if (
            theLine.velgProgress[i].school &&
            theLine.velgProgress[i].school.id &&
            theLine.velgProgress[i].school.id !== 'Lærling' &&
            (sameSchoolForWholeLine === '' || theLine.velgProgress[i].school.id === sameSchoolForWholeLine)
          ) {
            sameSchoolForWholeLine = theLine.velgProgress[i].school.id
          }
          // This is to know how many years in lærling, just to show appropriate text
          if (theLine.velgProgress[i].school &&
            theLine.velgProgress[i].school.id &&
            theLine.velgProgress[i].school.id === 'Lærling') {
            switch (parseInt(theLine.velgProgress[i].line.varighet)) {
              case 1:
                setAarSomLaerling('Det året')
                break;
              case 2:
                setAarSomLaerling('De to årene')
                break;
              case 3:
                setAarSomLaerling('De tre årene')
                break;
              case 4:
                setAarSomLaerling('De fire årene')
                break;
              default:
                setAarSomLaerling('Det året')
                break;
            }
          }
        }
        // Check if selected school for first years offer påbygg
        if (matchedSchools.length ) {
          if (sameSchoolForWholeLine !== '') {
            matchedSchools.forEach(school => {
              if (school.vilbli_org_id === sameSchoolForWholeLine) {
                setSkoleHarPaabygg(true);
              }
            })
          }
        }
      }
      setSkoler(matchedSchools)

      // If no school in the users fylke offer the line, find other fylke which offers to inform the user.
      if (!matchedSchools.length ) {
        let fylkerMedSkole = [];
        const andreFylker = theLine.skoleListe.filter(skoler => skoler.fylkenr !== user.fylke.id)
        theLine.velgProgress[currentLine].line.skoler.forEach(skole => {
          andreFylker.forEach(fylke => {
            if (fylke.skoler[skole]) {
              fylkerMedSkole.push(fylke.fylkenavn)
            }
          })
        })
        setAndreFylkerMedSkole(fylkerMedSkole)
      }
    }
  }, [currentLine, theLine.vgStage, theLine.skoleListe, theLine.velgProgress, user.fylke.id]);

  useEffect(() => {
    /**
     * If the selected school for previous stages doesn't offer påbygg,
     * then find schools offering the whole line.
     */
    if(!theLine.velgProgress[currentLine].line.children && !skoleHarPaabygg) {
      let skoleIderMedLopet = []
      for (let i=1; i<=currentLine; i++) {
        if (theLine.velgProgress[i].line.skoler && theLine.velgProgress[i].line.skoler[0] !== 'laerefag.vgs.no') {
          skoleIderMedLopet[i] = theLine.velgProgress[i].line.skoler
        }
      }
      // Get unique list
      skoleIderMedLopet = skoleIderMedLopet.reduce((a, b) => a.filter(c => b.includes(c)));
      // Now get only schools within users fylke
      let fylkesSkoler = theLine.skoleListe.find(s => s.fylkenr === user.fylke.id)
      let fylkesSkoleIder = fylkesSkoler && fylkesSkoler.skoler ? fylkesSkoler.skoler : []
      skoleIderMedLopet = Object.keys(fylkesSkoleIder).filter(s => skoleIderMedLopet.includes(s))
      // Now getting list of schools with and without the line
      let skolerMed = []
      let skolerUten = []
      skoler.forEach(s => {
        if (skoleIderMedLopet.includes(s.vilbli_org_id)) {
          skolerMed.push(s)
        } else {
          skolerUten.push(s)
        }
      })
      setSkolerMedLopet(skolerMed)
      setSkolerUtenLopet(skolerUten)
    }
  }, [currentLine, skoler, skoleHarPaabygg, theLine.velgProgress, theLine.vgStage, theLine.skoleListe, user.fylke.id])

  const goBackToVg = () => {
    // If no school available, send user back to VG selection which is two steps back.
    let flow = theLine.velgFlow
    if (flow.length > 1) {
      flow.splice(flow.length-2, 2) // Remove two last steps from the flow
      theLine.setVelgFlow(flow)
      let newStep = flow[flow.length - 1]
      theLine.setVelgStep(newStep);
      // Remove last selected line
      if (theLine.velgProgress[theLine.vgStage]) {
        let progress = theLine.velgProgress
        delete progress[theLine.vgStage]
        theLine.setVelgProgress(progress)
      }
    }
  }

  if (isLastYear && !skoleHarPaabygg && skolerMedLopet.length && skoler.length) {
    return (
      <div className="select-school">
        <div className="select-school--header">
          <h3>{theLine.velgProgress[1].school.name} har dessverre ikke påbygg. </h3>
          <div className="schools-without-description">
            {
              aarSomLaerling === '' ?
                <>
                  <p>Ved å gå på denne skolen på Vg1 og Vg2 må du bytte en til annen skole etter lærlingtiden for å ta påbygg.</p>
                  <p>Ønsker du å gå på den samme skolen før og etter lærlingtiden, så kan vi bytte  til en skole som tilbyr hele løpet ditt.</p>
                </>
                :
                <>
                  <p>Ved å gå på denne skolen må du bytte en til annen skole etter Vg2 for å ta påbygg.</p>
                  <p>Ønsker du ikke å bytte skole underveis, så kan vi bytte  til en skole som tilbyr hele løpet.</p>
                </>
            }
            <p><strong>Velg da
              {skolerMedLopet.map((skole, index) => {
                return (
                  <span key={index}> {skole.skolenavn}
                    {index < skolerMedLopet.length - 1 &&
                      <span> eller</span>
                    }
                  </span>
                );
              })}
              .</strong></p>
            {aarSomLaerling !== '' &&
              <p>OBS! {aarSomLaerling} du er lærling arbeider du i en bedrift i stedet for å gå på skolen.</p>
            }
          </div>
          <h2>Hvilken skole nå da?</h2>
        </div>
        <div className="select-school--schools">
          {skolerMedLopet.length ?
            skolerMedLopet.map(skole => {
              return (
                <div className="schools-with-line" key={skole.vilbli_org_id}>
                  <p>Skoler med hele løpet ditt</p>
                  <School changeAllSckools={true} key={skole.vilbli_org_id} label={skole.skolenavn} skoleId={skole.vilbli_org_id} link={skole.link} />
                </div>
              );
            })
            : ''
          }
          {skolerUtenLopet.map(skole => {
            return <School key={skole.vilbli_org_id} label={skole.skolenavn} skoleId={skole.vilbli_org_id} link={skole.link} />
          })}
        </div>
      </div>
    );
  } else if (skoler.length) {
    return (
      <div className="select-school">
        <div className="select-school--header">
          <p>{theLine.velgProgress[1].line.utdanningsprogram_tittel} tilbys på
            {skoler.length === 1 ?
            ' bare en skole' :
            ' flere skoler.'
            }
          </p>
          {skoler.length > 1 &&
            <h2>Hvilken skole vil du gå på?</h2>
          }
        </div>
        <div className="select-school--schools">
          {skoler.map(skole => {
            return <School key={skole.vilbli_org_id} label={skole.skolenavn} skoleId={skole.vilbli_org_id} link={skole.link} />
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className="select-school">
        <div className="select-school--header">
          <p>{theLine.velgProgress[1].line.utdanningsprogram_tittel} tilbys ikke i fylket ditt.</p>
          <p>Tilbys det kun i:</p>
          <ul>
            {andreFylkerMedSkole.map((f, index) => {
              return <li key={index}>{f}</li>
            })}
          </ul>
          <Button onClick={goBackToVg} variant="primary" size="fw" label="Velg en annen linje" />
        </div>
      </div>
    );
  }
}

export default VelgSkole;