import {ReactComponent as Bookmark} from "../../asset/bookmark.svg";
import {ReactComponent as Bookmarked} from "../../asset/bookmarked.svg";
import {useState, useEffect} from "react";

const BookmarkIcon = ({articleId}) => {
  const [bookmarked, setBookmarked] = useState(false);

  const getBookmarked = () => {
    let bookmarkedArticles = window.localStorage.getItem('bookmarked_articles');
    return bookmarkedArticles ? JSON.parse(bookmarkedArticles) : [];
  }

  useEffect(() => {
    const bookmarked = getBookmarked()
    if (bookmarked.length && bookmarked.includes(articleId)) {
      setBookmarked(true);
    }
  }, [articleId])

  const bookmarkArticle = () => {
    const bookmarkedArticles = getBookmarked()
    if (bookmarked) {
      bookmarkedArticles.splice(bookmarkedArticles.indexOf(articleId), 1)
      setBookmarked(false)
    } else {
      bookmarkedArticles.push(articleId)
      setBookmarked(true)
    }
    window.localStorage.setItem('bookmarked_articles', JSON.stringify(bookmarkedArticles))
  }

  if (bookmarked) {
    return <Bookmarked className="article--bookmark" onClick={bookmarkArticle}/>;
  } else {
    return <Bookmark className="article--bookmark" onClick={bookmarkArticle}/>;
  }
}

export default BookmarkIcon;