import React from 'react';
import clsx from 'clsx';

// Import icon as react component
import { ReactComponent as ChevronRight } from '../../asset/chevron-right.svg';
import { ReactComponent as CheckBoxBlank } from '../../asset/check-box-blank.svg';
import { ReactComponent as Close } from '../../asset/close.svg';
import { ReactComponent as ExpandMore } from '../../asset/expand-more.svg';
import { ReactComponent as CheckBoxIndeterminate } from '../../asset/check-box-indeterminate.svg';
import { ReactComponent as ContentCopy } from '../../asset/content-copy.svg';
import { ReactComponent as CheckBox } from '../../asset/check-box.svg';
import { ReactComponent as LogoColor } from '../../asset/logo-color.svg';
import { ReactComponent as LogoWhite } from '../../asset/logo-white.svg';
import { ReactComponent as Menu } from '../../asset/hamburger.svg';
import { ReactComponent as RadioChecked } from '../../asset/radio-checked.svg';
import { ReactComponent as RadioUnchecked } from '../../asset/radio-unchecked.svg';
import { ReactComponent as Bookmark } from '../../asset/bookmark.svg';
import { ReactComponent as Bookmarked } from '../../asset/bookmarked.svg';
import { ReactComponent as Heart } from '../../asset/heart.svg';
import { ReactComponent as Search } from '../../asset/search.svg';
import { ReactComponent as Home } from '../../asset/home.svg';
import { ReactComponent as Calendar } from '../../asset/calendar.svg';
import { ReactComponent as Comment } from '../../asset/comment.svg';
import { ReactComponent as Download } from '../../asset/download.svg';
import { ReactComponent as Info } from '../../asset/info.svg';
import { ReactComponent as OpenLink } from '../../asset/open-link.svg';
import { ReactComponent as StudyHat } from '../../asset/study-hat.svg';
import { ReactComponent as Sort } from '../../asset/sort.svg';

// Map icon component to name
const iconsMap = {
  bookmark: Bookmark,
  bookmarked: Bookmarked,
  calendar: Calendar,
  'check-box': CheckBox,
  'check-box-blank': CheckBoxBlank,
  'check-box-indeterminate': CheckBoxIndeterminate,
  'chevron-right': ChevronRight,
  close: Close,
  comment: Comment,
  'content-copy': ContentCopy,
  download: Download,
  'expand-more': ExpandMore,
  menu: Menu,
  heart: Heart,
  home: Home,
  info: Info,
  'logo-color': LogoColor,
  'logo-white': LogoWhite,
  'open-link': OpenLink,
  'radio-checked': RadioChecked,
  'radio-unchecked': RadioUnchecked,
  search: Search,
  sort: Sort,
  'study-hat': StudyHat
};

export const iconsName = Object.keys(iconsMap);

export const Icon = ({ name, size, className, ...props }) => {
  const IconName = iconsMap[name];
  const classAttr = clsx(
    'icon',
    name && `icon--${name}`,
    size && `icon--${size}`,
    className
  );

  if (!Object.keys(iconsMap).includes(name)) {
    console.warn(`Icon '${name}' not found.`);
    return null;
  }
  return <IconName className={classAttr} {...props} />;
};
