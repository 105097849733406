import React, {useContext, useState, useEffect, Suspense} from "react";
import Tema from "./Tema";
import EditProfile from "./EditProfile";
import MineLinjer from "./MineLinjer";
import {UserContext} from "../../context/UserContext";
import {Button} from "../../elements/Button";
import {ModalContext} from "../../context/ModalContext";
import UserAvatar from "./UserAvatar";
import SearchForm from "../../component/SearchForm";

const Profile = () => {
  const [section, setSection] = useState("linje");
  const [editProfile, setEditProfile] = useState(false);
  const [user] = useContext(UserContext);
  const theModal = useContext(ModalContext)
  const renderLoader = () => <p>Loading</p>;

  useEffect(() => {}, []);

  const changeAvatar = () => {
    theModal.setModal('pages/Profile/ChangeAvatar')
  }

  return (
    <>
      <div className="page__header">
        <Suspense fallback={renderLoader()}>
          <SearchForm formId="articlesSearch"/>
        </Suspense>
      </div>

      <div className="profile-page">
        <header>
          <svg width="750" height="675" viewBox="0 0 750 675" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M750 0H0V481.21V631.12C112.5 706.075 243.75 668.597 375 631.12C506.25 593.643 637.5 556.165 750 631.12V481.21V0Z" fill="url(#paint0_linear_107_5)"/>
            <defs>
              <linearGradient id="paint0_linear_107_5" x1="6.58225e-06" y1="275.084" x2="791.9" y2="546.713" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F00F46"/>
                <stop offset="1" stopColor="#FDAB3C"/>
              </linearGradient>
            </defs>
          </svg>
          <UserAvatar avatarId={user.avatar} />
          <div className="user-info">
            <h1>{user.fornavn} {user.etternavn}</h1>
            <span onClick={changeAvatar}>Bytt avatar</span>
          </div>
        </header>
        <nav>
          <Button variant="secondary"
                  className={section === "linje" ? "active" : null}
                  onClick={() => setSection("linje")}
                  iconBefore="heart"
                  label="Min linje"
          />
          <Button variant="secondary"
                  iconBefore={section === "tema" ? 'bookmarked' : 'bookmark'}
                  className={section === "tema" ? "active" : null}
                  onClick={() => setSection("tema")}
                  label="Tema"
          />
        </nav>
        <main>
          {section === "linje" ?
            <MineLinjer /> :
            <Tema />
          }
        </main>
        {editProfile &&
          <EditProfile setEditProfile={setEditProfile} />
        }
      </div>
    </>
  );
};

export default Profile;