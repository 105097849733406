import React, {useState, createContext} from 'react';

export const ModalContext = createContext();

export const ModalProvider = props => {
  const [modal, setModal] = useState('')
  const [className, setClassName] = useState('')
  const theModal = {
    modal,
    setModal,
    className,
    setClassName
  }

  return (
    <ModalContext.Provider value={theModal}>
      {props.children}
    </ModalContext.Provider>
  );
}