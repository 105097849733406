import React, {useEffect, Suspense, lazy} from 'react';
import clsx from "clsx";

/**
 * A custom made Modal component.
 * @param classNames Extra classNames. For example, if you want it to be full screen, add 'full-screen-modal'
 * @param onClose Set it to null to close or padd a new modal path to open it
 * @param content the path of the component to be rendered inside the modal without '../' (to overcome a WebPack shortage)
 * @return {JSX.Element}
 * @constructor
 */
const TheModal = ({classNames, onClose, content}) => {
  let ChildComponent
  if (typeof content === 'string') {
    ChildComponent = lazy(() => import(`../${content}`));
  } else {
    ChildComponent = content ? content.modalComponent : <></>
  }

  const classAttr = clsx(classNames, 'modal');
  const renderLoader = () => <p>Loading</p>;

  useEffect( () => {
    window.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        onClose(null)
      }
    })
  }, [onClose]);

  return (
    <div className={classAttr}>
      <Suspense fallback={renderLoader()}>
        <ChildComponent onClose={onClose} />
      </Suspense>
    </div>
  );
};

export default TheModal;