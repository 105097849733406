import React, { useEffect, useContext, useState } from "react";
import {LineContext} from "../../context/LineContext";
import {UserContext} from "../../context/UserContext";
import LineSummary from "./LineSummary";
import Vg1 from "./Vg1";
import VgOthers from "./VgOthers";
import VelgSkole from "./VelgSkole";
import VelgFylke from "./VelgFylke";
import VelgDirection from "./VelgDirection";
import Summary from "./Summary";
import EtterLaerling from "./EtterLaerling";

const Steps = () => {
  const theLine = useContext(LineContext);
  const [user] = useContext(UserContext);
  const [renderComponent, setRenderComponent] = useState('vg1');
  useEffect(() => {
    let step = theLine.velgStep
    let steps = theLine.steps
    if (steps.vg1.includes(step)) {
      setRenderComponent('vg1')
    } else if (steps.lineSummary.includes(step)) {
      setRenderComponent('lineSummary')
    } else if (steps.velgSkole.includes(step)) {
      if (user.fylke && user.fylke.id === 0) {
        setRenderComponent('velgFylke')
      } else {
        setRenderComponent('velgSkole')
      }
    } else if (steps.vgOthers.includes(step)) {
      setRenderComponent('vgOthers')
    } else if (steps.velgDirection.includes(step)) {
      setRenderComponent('velgDirection')
    } else if (steps.summary.includes(step)) {
      setRenderComponent('summary')
    } else if (steps.etterLaerling.includes(step)) {
      setRenderComponent('etterLaerling')
    }
  }, [theLine.velgStep, theLine.velgProgress, theLine.vgStage, theLine.steps, user]);

const renderSwitch = () => {
  switch (renderComponent) {
    case 'vg1':
      return <Vg1 />
    case 'vgOthers':
      return <VgOthers />
    case 'lineSummary':
      return <LineSummary />
    case 'velgDirection':
      return <VelgDirection />
    case 'velgSkole':
      return <VelgSkole />
    case 'velgFylke':
      return <VelgFylke />
    case 'summary':
      return <Summary />
    case 'etterLaerling':
      return <EtterLaerling />
    default:
      return 'Something vent wrong!'
  }
}
  if (theLine.error) {
    return <div>Error: {theLine.error.message}</div>;
  } else if (!theLine.isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        {renderSwitch()}
      </>
    )
  }
}

export default Steps
