import {ReactComponent as Search} from "../../asset/search.svg";
import clsx from "clsx";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const SearchForm = ({onSearch, formId, formClass}) => {
  const navigate = useNavigate();

  /**
   * On search,if articleSearch, navigate with query params in the URL to home
   * if faqSearch, don't navigate, fire onSearch event which is handled on faq component
   */
  const doTheSearch = (e) => {
    e.preventDefault()
    const searchTerm = e.target.querySelector('.search-form__input').value
    if (['articlesSearch', 'menuSearch'].includes(formId)) {
      navigate('/?sok=' + searchTerm);
    } else if (formId === 'faqSearch') {
      onSearch(searchTerm)
    }
  }

  // If user clicks on x inside the search field, this fires and cleans the URL query params
  useEffect(() => {
    document.querySelector('.search-form__input').addEventListener('search', e => {
      if (e.target.value === '') {
        navigate(window.location.pathname);
      }
    })
  }, [navigate])

  return (
    <>
      <div className={clsx(formClass, 'search-form')}>
        <form onSubmit={doTheSearch}>
          <input type="search" name="search" id={formId} className="search-form__input" placeholder="Søk på ord du lurer på..."/>
          <button type="submit" className="button button--md button--icon has-icon-before button--search" aria-label="Søk">
            <Search />
          </button>
        </form>
      </div>
    </>
  );
}

export default SearchForm;