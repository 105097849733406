import avatar1 from "./../../../asset/profile/1.svg";
import avatar2 from "./../../../asset/profile/2.svg";
import avatar3 from "./../../../asset/profile/3.svg";
import avatar4 from "./../../../asset/profile/4.svg";
import avatar5 from "./../../../asset/profile/5.svg";
import avatar6 from "./../../../asset/profile/6.svg";
import avatar7 from "./../../../asset/profile/7.svg";
import avatar8 from "./../../../asset/profile/8.svg";

import React from "react";

const UserAvatar = ({avatarId, alt}) => {
  switch (avatarId) {
    case 1:
      return <img className="user-avatar" src={avatar1} alt={alt} />
    case 2:
      return <img className="user-avatar" src={avatar2} alt={alt} />
    case 3:
      return <img className="user-avatar" src={avatar3} alt={alt} />
    case 4:
      return <img className="user-avatar" src={avatar4} alt={alt} />
    case 5:
      return <img className="user-avatar" src={avatar5} alt={alt} />
    case 6:
      return <img className="user-avatar" src={avatar6} alt={alt} />
    case 7:
      return <img className="user-avatar" src={avatar7} alt={alt} />
    case 8:
      return <img className="user-avatar" src={avatar8} alt={alt} />
    default:
      return 'Something vent wrong!'
  }
}

export default UserAvatar;