import {Link, useParams} from "react-router-dom";
import React, {Suspense, useEffect, useState} from "react";
import clsx from "clsx";
import BookmarkIcon from "../elements/BookmarkIcon";
import {Button} from "../elements/Button";
import {ToTheTop} from "../elements/ToTheTop";
import {Icon} from "../elements/Icon";
import SearchForm from "../component/SearchForm";
import Profile from "../component/Profile";

const Article = () => {
  const [article, setArticle] = useState([]);
  const [image, setImage] = useState('');
  const articleParam = useParams();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const renderLoader = () => <p>Loading</p>;

  useEffect( () => {
    fetch(`https://api.utdanning.no/linje/artikkel/${articleParam.nid}`)
      .then(res => res.json())
      .then(
        (result) => {
          setArticle(Array.isArray(result) ? result[0] : result);
          setIsLoaded(true);
          detailsToModal();
        },
        (error) => {
          setIsLoaded(false);
          setError(error);
        }
      )

    // Here we manipulate DOM to change structure of details to something like button => modal
    const detailsToModal = () => {
      setTimeout(() => {
        let details = document.querySelectorAll('details');
        details.forEach(detail => {
          let content = detail.querySelector('p');
          let summary = detail.querySelector('summary');
          let close  = document.createElementNS("http://www.w3.org/2000/svg", "svg");
          close.setAttributeNS(null, 'height', '18')
          close.setAttributeNS(null, 'width', '18')
          close.innerHTML = '<path d="M16.5 1.5L1.5 16.5" stroke="#002889" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
            '<path d="M1.5 1.5L16.5 16.5" stroke="#002889" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>';
          close.addEventListener('click', () => {
            closeModal()
          });
          let span = document.createElement('div')
          span.innerHTML = content.innerHTML
          let body = document.createElement('div')
          body.classList.add('details-modal-body')
          let detailTitle = document.createElement('h3')
          detailTitle.innerText = summary.innerText
          body.append(detailTitle)
          body.append(span)

          content.innerHTML = null
          content.prepend(body);
          content.append(close)
        })
        window.addEventListener('keyup', e => {
          if (e.key === 'Escape') {
            closeModal()
          }
        })
      }, 1000)
    }

    const closeModal = () => {
      let openDetail = document.querySelector('details[open]')
      openDetail.open = false;
      openDetail.querySelector('summary').style.outline = 'none';
    }

  }, [articleParam]);

  useEffect(() => {
    // Import images dynamically based on article category fetched from API
    if (article && article.kategori) {
      let category = article.kategori.toLowerCase();
      category = category.replace(/\s/g, '-', category)
      import(`../../asset/images/${category}-crop.svg`).then(image => {
        setImage(image.default)
      })
    }
  }, [article])

  const scrollToBody = () => {
    window.scrollTo({
      top: document.querySelector('#articleContent').getClientRects()[0].y,
      left: 0
    })
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <div className="page__header">
          <Suspense fallback={renderLoader()}>
            <SearchForm formId="articlesSearch"/>
          </Suspense>
          <Profile />
        </div>
        <div className="single-article-container">
          <div className="article-back-button">
            <Link to="/">
              <Button variant="text" iconBefore="chevron-right" label="Gå tilbake" />
            </Link>
          </div>
          <div className={clsx("article single-article")}>
            <div className="article-header">
              <img src={image} alt={article.tittel}/>
              <BookmarkIcon articleId={article.nid}/>
              <h3 className="category">{article.kategori}</h3>
              <h1 className="article__title">{article.tittel}</h1>
              <p className="article__excerpt">{article.ingress}</p>
              <Icon onClick={scrollToBody} className="slide-to-content" name="chevron-right" size="lg" />
            </div>
            <div className="article-content" id="articleContent">
              {article.paragraphs.map((item, index) => {
                return (
                  <section className="body-section" key={index}>
                    <h2>{item.tittel}</h2>
                    <div className="body-section__body" dangerouslySetInnerHTML={{ __html: item.tekst}} />
                    { item.knapp_tekst &&
                      <a className="button button--fw button--secondary" href={item.knapp_href}>{item.knapp_tekst}</a>
                    }
                  </section>
                );
              })}
            </div>
          </div>
          <ToTheTop />
        </div>
      </>
    );
  }
};
export default Article;