import React, {lazy, Suspense, useEffect, useState} from "react";
import {ReactComponent as Comment} from "../../asset/comment.svg";
import clsx from "clsx";
import Profile from "../component/Profile";

const SearchForm = lazy(() => import('../component/SearchForm'))
const renderLoader = () => <p>Loading</p>;

const Faq = () => {
  const [faq, setFaq] = useState({});
  const [searchResults, setSearchResults] = useState({});
  const [open, setOpen] = useState(null);

  useEffect( () => {
    async function fetchData() {
      const response = await fetch(`https://api.utdanning.no/linje/faq`)
      return await response.json()
    }
    fetchData().then(faqs => {
      if (faqs && Array.isArray(faqs)) {
        let faq = {}
        for (let i=0; i < faqs.length; i++) {
          if (typeof faq[faqs[i].kategori] === 'undefined') {
            faq[faqs[i].kategori] = []
          }
          faq[faqs[i].kategori].push(faqs[i])
        }
        setFaq(faq)
        setSearchResults(faq)
      }
    })
  }, []);

  /**
   * Here I a simple javascript based search since FAQ page doesn't contain lots of text
   * and this will have instantaneous results.
   * But if you want to use API search, comment the JS search part and uncomment the API part
   */
  const doSearch = s => {
    let searchResults = []
    // JS search
    for (let i=0; i < Object.keys(faq).length; i++) {
      let res = faq[Object.keys(faq)[i]].filter(f => f.tittel.toLowerCase().indexOf(s.toLowerCase()) > -1 || f.tekst.toLowerCase().indexOf(s.toLowerCase()) > -1)
      if (res.length) searchResults[Object.keys(faq)[i]] = res
    }

    // API search
    // let url = new URL("https://api.utdanning.no/linje/sok");
    // url.searchParams.append('Query', s)
    // url.searchParams.append('Type', 'linje_faq')
    // fetch(url)
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       searchResults = result.results
    //     },
    //     (error) => {
    //     }
    //   )

    setSearchResults(searchResults)
  }

  return (
    <>
      <div className="page__header">
        <Suspense fallback={renderLoader()}>
          <SearchForm onSearch={e => {
            doSearch(e)
          }} formId="faqSearch"/>
        </Suspense>
        <Profile />
      </div>
      <div className="faq page-title">
        <Comment />
        <h1>Hva lurer du på?</h1>
      </div>
      <Suspense fallback={renderLoader()}>
        <SearchForm
          onSearch={e => {doSearch(e)}}
          formId="faqSearch"
          formClass="faq-search"/>
      </Suspense>
      <div className="faq-qas">
        {Object.keys(searchResults).length
          ?
          <>
            {Object.keys(searchResults).map((f, i) => {
              return (
                <div key={i}>
                  <h2>{f}</h2>
                  {searchResults[f].map((qa, j) => {
                    return (
                      <div className={clsx({'open': open === String(i) + String(j)}, 'question-answer')} key={String(i) + String(j)}>
                        <div className="question" onClick={() => {setOpen(open === String(i) + String(j) ? '' : String(i) + String(j))}}>{qa.tittel}</div>
                        <div className="answer" dangerouslySetInnerHTML={{ __html: qa.tekst}} />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </>
          :
          <div className="pb-sm">
            <h2>Ingen resultater</h2>
          </div>
        }
      </div>
    </>
  );
};

export default Faq;
