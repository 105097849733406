import React, {useContext} from 'react';
import TheModal from "../../component/Modal";
import {ModalContext} from "../../context/ModalContext";

export const Main = ({ children }) => {
  const theModal = useContext(ModalContext)
  return (
    <>
      <main className="layout__main">{children}</main>
      {theModal.modal &&
        <TheModal content={theModal.modal} onClose={(m) => {theModal.setModal(m)}} classNames={theModal.className} />
      }
    </>
  );
};
