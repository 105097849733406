import React, {useState, useEffect, createContext} from 'react';

export const UserContext = createContext();

export const UserProvider = props => {
  const [user, setUser] = useState({
    fornavn: 'Markus',
    etternavn: 'Simonsen',
    avatar: 1,
    fylke: {
      id: 0,
      name: ''
    }
  });

  useEffect(() => {
    let storedUserData = localStorage.getItem('userData')
    storedUserData = storedUserData ? JSON.parse(storedUserData) : {}
    if (storedUserData && storedUserData.fornavn) {
      setUser(storedUserData)
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('userData', JSON.stringify(user))
  }, [user]);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
}