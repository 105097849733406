import {ReactComponent as Calendar} from "../../asset/calendar.svg";
import {Button} from "../elements/Button";
import React, {Suspense, useEffect, useState} from "react";
import CalendarEvent from "../component/CalendarEvent";
import Profile from "../component/Profile";
import SearchForm from "../component/SearchForm";

const Kalender = () => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const renderLoader = () => <p>Loading</p>;

  useEffect( () => {
    fetch(`https://api.utdanning.no/linje/kalender`)
      .then(res => res.json())
      .then(
        (result) => {
          if (result && Array.isArray(result)) {
            result.sort((a, b) => new Date(a.dato) - new Date(b.dato))
            setEvents(result)
          }
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(false);
          setError(error)
        }
      )
  }, []);

  const makeIcsFile = () => {
    let icsFile = null;
    let icsContent =
      "BEGIN:VCALENDAR\n" +
      "CALSCALE:GREGORIAN\n" +
      "METHOD:PUBLISH\n" +
      "PRODID:-//Linje kalender//EN\n" +
      "VERSION:2.0\n";
    for (let i = 0; i < events.length; i++) {
      const start = events[i].start,
        end = events[i].end,
        id = events[i].id,
        summary = events[i].title,
        description = events[i].description;
      icsContent +=
      "BEGIN:VEVENT\n" +
      "UID:event-" + id + "\n" +
      "DTSTART;VALUE=DATE:" + start + "\n" +
      "DTEND;VALUE=DATE:" + end + "\n" +
      "SUMMARY:" + summary + "\n" +
      "DESCRIPTION:" + description + "\n" +
      "END:VEVENT\n";
    }
    icsContent += "END:VCALENDAR";

    let data = new File([icsContent], 'events', { type: "text/plain" });

    // If we are replacing a previously generated file we need to
    // manually revoke the object URL to avoid memory leaks.
    if (icsFile !== null) {
      window.URL.revokeObjectURL(icsFile);
    }

    icsFile = window.URL.createObjectURL(data);

    return icsFile;
  }

  const downloadIcs = () => {
    const link = document.getElementById("icsDownloadLink");
    link.href = makeIcsFile();
    link.click()
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <div className="page__header">
          <Suspense fallback={renderLoader()}>
            <SearchForm formId="articlesSearch"/>
          </Suspense>
          <Profile />
        </div>
        <div className="kalender">
          <div className="page-header">
            <div className="page-title">
              <Calendar/>
              <h1>Kalender</h1>
            </div>
            <div className="header-action">
              <p>Last ned kalenderen, så sender vi deg påminnelse én uke før fristene.</p>
              <div className="calendar-action">
                <Button variant="secondary" iconBefore="download" label="Lagre kalenderhendelsene" onClick={downloadIcs}/>
                <a href="/" id="icsDownloadLink" download="event.ics" className="icsFile">ICS file</a>
              </div>
            </div>
          </div>
          <div className="events">
            {events.map((event) => {
              return <CalendarEvent event={event} key={event.nid}/>
            })}
          </div>
        </div>
      </>
    );
  }
};

export default Kalender;
