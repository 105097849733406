import React, {lazy, Suspense, useEffect} from 'react';
import {useLocation, Link} from "react-router-dom";
import clsx from 'clsx';

import { ReactComponent as Logo } from '../../asset/logo-white.svg';
import { ReactComponent as LogoText } from '../../asset/logo-text.svg';
import { ReactComponent as Hamburger } from '../../asset/hamburger.svg';

const MainMenu = lazy(() => import('./MainMenu'))
const renderLoader = () => <p>Loading</p>;

export const Header = ({ className }) => {
  const [ menuOpen, setMenuOpen ] = React.useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  useEffect(() => {
      window.addEventListener('keyup', e => {
        if (e.key === 'Escape') {
          setMenuOpen(false);
        }
      })
  }, []);
  const location = useLocation();
  useEffect(() => {
    setMenuOpen(false)
    }, [location])

  return (
    <div className="site-menu">
      <div className={clsx('site-header', {'menu-open':menuOpen}, className)}>
        <div className="logo">
          <Link to="/">
            <span className="visually-hidden">Linje</span>
            <Logo className="site-header__symbol" />
            <LogoText className="site-header__text" />
          </Link>
        </div>
        <div className="site-header__menu" onClick={toggleMenu}>
          <Hamburger className="site-header__hamburger" />
        </div>
      </div>
      <Suspense fallback={renderLoader()}>
        <MainMenu className={clsx({open: menuOpen})}/>
      </Suspense>
    </div>
  );
};
