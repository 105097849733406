import React, { useState } from "react";
import { ReactComponent as Avatar1 } from "../../../asset/profile/1.svg";
import { ReactComponent as Avatar2 } from "../../../asset/profile/2.svg";
import { ReactComponent as Avatar3 } from "../../../asset/profile/3.svg";
import { ReactComponent as Avatar4 } from "../../../asset/profile/4.svg";
import { ReactComponent as Avatar5 } from "../../../asset/profile/5.svg";
import { ReactComponent as Avatar6 } from "../../../asset/profile/6.svg";
import { ReactComponent as Avatar7 } from "../../../asset/profile/7.svg";
import { ReactComponent as Avatar8 } from "../../../asset/profile/8.svg";

const EditProfile = ({ setEditProfile }) => {
  const [profilePic, setProfilePic] = useState(1);
  return (
    <>
      <div className="edit-profile">
        <Avatar1
          className={profilePic === 1 ? "active" : null}
          onClick={() => {
            setProfilePic(1);
            setEditProfile(false);
          }}
        />
        <Avatar2
          className={profilePic === 2 ? "active" : null}
          onClick={() => {
            setProfilePic(2);
            setEditProfile(false);
          }}
        />
        <Avatar3
          className={profilePic === 3 ? "active" : null}
          onClick={() => {
            setProfilePic(3);
            setEditProfile(false);
          }}
        />
        <Avatar4
          className={profilePic === 4 ? "active" : null}
          onClick={() => {
            setProfilePic(4);
            setEditProfile(false);
          }}
        />
        <Avatar5
          className={profilePic === 5 ? "active" : null}
          onClick={() => {
            setProfilePic(5);
            setEditProfile(false);
          }}
        />
        <Avatar6
          className={profilePic === 6 ? "active" : null}
          onClick={() => {
            setProfilePic(6);
            setEditProfile(false);
          }}
        />
        <Avatar7
          className={profilePic === 7 ? "active" : null}
          onClick={() => {
            setProfilePic(7);
            setEditProfile(false);
          }}
        />
        <Avatar8
          className={profilePic === 8 ? "active" : null}
          onClick={() => {
            setProfilePic(8);
            setEditProfile(false);
          }}
        />
      </div>
    </>
  );
};

export default EditProfile;
