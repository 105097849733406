import React from "react";
import Steps from "./Steps";
import VelgHeader from "./VelgHeader";

const Velg = () => {
  return (
    <div className="select-your-line">
      <VelgHeader />
      <Steps />
    </div>
  );
};

export default Velg;
