/**
 * Save the line user is working on.
 * @param theLine Object the Line Context
 * @param cleanProgress Boolean if set to True, the current progress will be removed
 * @return boolean
 */
export const saveTheLine = (theLine, cleanProgress = false) => {
  let savedLines = localStorage.getItem('savedLines')
  savedLines = savedLines ? JSON.parse(savedLines) : []
  savedLines.push(theLine.velgProgress)
  localStorage.setItem('savedLines', JSON.stringify(savedLines))
  if (cleanProgress) {
    removeCurrentLine(theLine)
  }
  return true;
}

/**
 * Remove currently in progress line from Context and localStorage
 * @param theLine Object the Line Context
 * @return boolean To indicate the function is done what it suppose to do
 */
export const removeCurrentLine = (theLine) => {
  localStorage.setItem('velgProgress', JSON.stringify({}))
  localStorage.setItem('lineVelgStep', JSON.stringify(1001))
  localStorage.setItem('lineVelgFlow', JSON.stringify([]))
  theLine.setVelgProgress({})
  theLine.setVelgStep(1001)
  theLine.setVelgFlow([])
  return true;
}