import React, {useContext} from "react";
import {LineContext} from "../../context/LineContext";
import { StudyProgram } from "../../component/StudyProgram";
import {Button} from "../../elements/Button";
import {Icon} from "../../elements/Icon";
import {ToTheTop} from "../../elements/ToTheTop";
import {ModalContext} from "../../context/ModalContext";

const Vg1 = ({ items }) => {
  const theLine = useContext(LineContext)
  const theModal = useContext(ModalContext)

  // TODO: Add correct text, for now there is only placeholder taken from design
  const modalText = {
    Studiekompetanse: {
      title: 'Studiekompetanse',
      text: 'kvalifikasjon som kan oppnås etter å ha fullført og bestått videregående opplæring i Norge.'
    },
    Yrkeskompetanse: {
      title: 'Yrkeskompetanse',
      text: 'Tekst for Yrkeskompetanse som kommer etterpå!'
    }
  }

  const openTheModal = (m) => {
    const ModalComp = ({onClose}) => {
      return (
        <div className="info-modal">
          <div className="modal-header">
            <h2 className="modal-header"><Icon name="info" />{m.title}</h2>
            <Icon name="close" onClick={() => onClose(null)} />
          </div>
          <p className="modal-text">{m.text}</p>
        </div>
      );
    }
    theModal.setClassName('info-modals')
    theModal.setModal({modalComponent: ModalComp})
  }
  return (
    <>
      <div className="page-header">
        <h1 className="class-stage">Vg1:</h1>
        <div className="stage-desc">Utforske utdanningsprogrammene</div>
        <hr />
        <div className="abbreviations">
          <p>Studiekompetanse
            <Button variant="text" onClick={() => openTheModal(modalText.Studiekompetanse)} className="more-info" iconBefore="info" label="" />
          </p>
          <p>
            Yrkeskompetanse
            <Button variant="text" onClick={() => openTheModal(modalText.Yrkeskompetanse)} className="more-info" iconBefore="info" label="" />
          </p>
        </div>
        <hr />
      </div>
      <div className="program-areas">
        {theLine.items.map((item) => (
          <StudyProgram
            key={item.programomradekode6}
            keyId={item.programomradekode6}
            type={item.utdanningsprogram_type}
            label={item.utdanningsprogram_tittel}
            areaCode={item.programomradekode6}
          />
        ))}
      </div>
      <ToTheTop />
    </>
  );
};

export default Vg1;