import React, {useEffect, useContext} from "react";
import {LineContext} from "../../context/LineContext";
import {UserContext} from "../../context/UserContext";
import {Icon} from "../../elements/Icon";

const VelgDirection = () => {
  const theLine = useContext(LineContext);
  const [user] = useContext(UserContext);

  useEffect(() => {

  }, []);

  const handleClick = (direction) => {
    let valg = theLine.velgProgress[theLine.vgStage].line
    let isYrke = false
    if (valg.children) {
      valg.children.map(v => {
        if (['yrkeskompetanse', 'fagbrev', 'svennebrev'].includes(v.sluttkompetanse.toLowerCase())) {
          isYrke = true
        }
        return null;
      })
    }
    let increment = direction === 'laerling' ? isYrke ? 1097 : 1197 : 997
    theLine.setVelgStep(theLine.velgStep + increment)
  }

  return (
    <div className="select-direction">
      <div className="select-direction--header">
        <p>{user.fornavn}, du har nå to valg:</p>
        <ol className="direction-choices">
          <li>Teste ut yrket som helsefagarbeider for  å se om det er noe for deg. Da er du lærling i to år i bedrift.</li>
          <li>Fortsette på skolen. Da går du på   påbygg i et år.  </li>
        </ol>
        <h2>Hva vil du gjøre nå?</h2>
      </div>
      <div className="select-direction--choices">
        <button
          className="program-area button--Yrkeskompetanse"
          type="button"
          onClick={() => handleClick('laerling')}
        >
          <div className="program-area--labels">
            <h2 className="program-name">Lærling</h2>
            <div className="program-section">Yrkes- og studiekompetanse</div>
          </div>
          <div className="program-area--icon-after">
            <Icon className="icon--after" size="lg" name="chevron-right" />
          </div>
        </button>
        <button
          className="program-area button--Studiekompetanse"
          type="button"
          onClick={() => handleClick('paabygg')}
        >
          <div className="program-area--labels">
            <h2 className="program-name">Påbygg</h2>
            <div className="program-section">Studiekompetanse</div>
          </div>
          <div className="program-area--icon-after">
            <Icon className="icon--after" size="lg" name="chevron-right" />
          </div>
        </button>

      </div>
    </div>
  );
}

export default VelgDirection;