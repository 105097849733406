import React, { useState, useEffect } from "react";
import ArticleTeaser from "../../component/ArticleTeaser";

const Tema = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    // Bookmarked articles list.
    const bookmarks = JSON.parse(localStorage.getItem("bookmarked_articles"));
    // If any, API request to fetch only articles bookmarked by the user
    if (bookmarks) {
      let url = new URL("https://api.utdanning.no/linje/artikkel");
      url.searchParams.append('fields', 'nid,tittel,ingress,kategori')
      url.searchParams.append('nid', bookmarks.toString())
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            if (result) {
              setArticles(result);
            }
          },
          (error) => {}
        )
    }

  }, []);

  return (
    <>
      {articles && articles.length
        ? articles.map((article, index) => (
          <ArticleTeaser articleContent={article} key={index} />
        ))
        : <p>Ingen artikkel i favorit listen!</p> }
    </>
  );
};

export default Tema;
