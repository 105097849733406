import React from 'react';
import clsx from 'clsx';
import { Icon } from './Icon';

export const Button = ({
                         label,
                         onClick,
                         size,
                         className,
                         iconBefore,
                         iconAfter,
                         amount,
                         variant,
                         ...props
                       }) => {
  const classAttr = clsx(
    'button',
    `button--${size}`,
    `button--${variant}`,
    iconBefore && 'has-icon-before',
    iconAfter && 'has-icon-after',
    className
  );
  return (
    <button
      className={classAttr}
      type="button"
      onClick={onClick}
      {...props}
    >
      {iconBefore && (
        <Icon className="icon--before" size={size} name={iconBefore} />
      )}
      <span>
                {label} {!!amount && `(${amount})`}
            </span>
      {iconAfter && (
        <Icon className="icon--after" size={size} name={iconAfter} />
      )}
    </button>
  );
};
