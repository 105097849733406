import React, {useContext, useState} from "react";
import { Icon } from "../../elements/Icon";
import {LineContext} from "../../context/LineContext";

export const School = ({label, link, skoleId, changeAllSckools, ...props}) => {
  const theLine = useContext(LineContext);
  const [currentLine] = useState(Object.keys(theLine.velgProgress).length);

  const handleClick = () => {
    let velgProgress = theLine.velgProgress
    velgProgress[currentLine] = {
      school: {name: label, id: skoleId},
      line: velgProgress[currentLine].line || {}
    }
    if (changeAllSckools) {
      Object.keys(velgProgress).map(l => {
        if (velgProgress[l].school && velgProgress[l].school.id !== 'Lærling') {
          velgProgress[l].school = {
            id: skoleId,
            name: label
          }
        }
        return null;
      })
    }
    theLine.setVelgProgress(velgProgress)
    let isYrkesfag = false
    let isLast = true
    if (velgProgress[currentLine].line && velgProgress[currentLine].line.children) {
      isLast = false
      velgProgress[currentLine].line.children.map(l => {
        if (l.opplaeringssted.toLowerCase() !== 'skole' || l.sluttkompetanse.toLowerCase() === 'yrkeskompetanse') {
          isYrkesfag = true
        }
        return null;
      })
    }
    if (isYrkesfag || isLast || theLine.vgStage === 3) {
      theLine.setVelgStep(theLine.velgStep + 1);
    } else {
      theLine.setVelgStep(theLine.velgStep + 998);
    }
  };

  return (
    <button type="button" {...props} onClick={() => handleClick()}>
      <div className="school-labels">
        <h3 className="school-name">{label}</h3>
        <a className="school-link" href={link} target="_blank" rel="noreferrer"
           title="Åpne skoledetaljer siden i en ny fane">
          <Icon size="lg" name="open-link" />
        </a>
      </div>
      <div className="school-icon-after">
        <Icon size="lg" name="chevron-right" />
      </div>
    </button>
  );
};
